import type { IButtonProps } from '@components/ButtonGroup/types';
import type { HeadingStyles, HeadingTags } from '@components/Typography/Heading/types';
import type { AlignmentEnum } from '../../types';
import type { IAemComponent } from '@components/PageRenderer/v2/types';
import { IVisibility } from '@components/Visibility/types';

export enum VerticalAlignmentEnum {
    TOP = 'top',
    BOTTOM = 'bottom',
    CENTER = 'center',
}

interface IImage {
    alt: string;
    fileReference: string;
    fileReferenceMobile?: string;
    height?: string | number;
    url?: string;
    width?: string | number;
}

interface IHeading {
    alignment?: string;
    tag?: HeadingTags;
    text: string;
    type?: HeadingStyles;
}

export interface IHeroImage {
    buttons?: IButtonProps[];
    componentTag?: string;
    data?: string;
    description?: string;
    disableImageLazyLoading?: boolean;
    hasOverlay?: boolean;
    overlayColor?: string;
    heading?: IHeading;
    heightType?: '50' | '75';
    image: IImage;
    tagStyle?: 'red' | 'gray';
    textAlignment?: AlignmentEnum;
    time?: string;
    verticalAlignment?: VerticalAlignmentEnum;
    video?: {
        path: string;
        type: string;
    };
    uuid?: string;
}

export interface IHeroImageAemProps extends IHeroImage, IVisibility, IAemComponent {}
